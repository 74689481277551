import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/heroes/social2.jpg';
import imgBlock1 from '../../assets/img/heroes/social_branding.jpg';
import imgBlock3 from '../../assets/img/heroes/remarketing.jpg';
import imgBlock2 from '../../assets/img/heroes/social_ads.jpg';
import SimilarCard from '../../components/similar.card';
import similar1 from '../../assets/img/heroes/thumbnails/website.jpg';
import similar2 from '../../assets/img/heroes/thumbnails/mobile.jpg';
import similar3 from '../../assets/img/heroes/thumbnails/newsletter.jpg';
import SEO from '../../components/seo';

import AniLink from 'gatsby-plugin-transition-link/AniLink';

const boxStyle1 = {
  backgroundImage: 'url(' + imgBlock1 + ')',
  backgroundPosition: 'center'
};
const boxStyle2 = {
  backgroundImage: 'url(' + imgBlock2 + ')',
  backgroundPosition: 'center'
};
const boxStyle3 = {
  backgroundImage: 'url(' + imgBlock3 + ')',
  backgroundPosition: 'center'
};

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Référencement | Agence digitale | MDS Digital Agency"
      description="Nous utilisons les dernières innovations en matière de référencement pour rendre votre site internet visible sur les moteurs de recherche."
      keywords="référencement"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Réseaux sociaux</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em' }}>
        <h4 className={pageStyle.intro}>
          Et si vous faisiez des réseaux sociaux votre outil de communication ? Être présent sur les
          réseaux sociaux est indispensable pour une entreprise qui veut être proche de ses
          consommateurs. Que vous désiriez construire une communauté autour de votre marque ou
          diffuser votre publicité, MDS Digital Agency vous accompagne tout au long de votre projet.
        </h4>
      </div>

      <div style={{ marginBottom: 0 }} className={pageStyle.box_container_white}>
        <div
          style={boxStyle1}
          className={`${pageStyle.box_panel_left} ${pageStyle.mobile_hidden}`}
        ></div>
        <div style={{ justifyContent: 'start' }} className={pageStyle.box_panel_right}>
          <div className={pageStyle.box_content}>
            <h5>Social Branding</h5>
            <p>Partagez vos idées et ancrez votre image de marque auprès de vos consommateurs.</p>
            <p>Nous sommes là pour vous aider à accroitre votre communauté.</p>
            <div style={{ textAlign: 'left' }} className={pageStyle.cta}>
              <AniLink
                to="/services/reseaux-sociaux/social-branding/"
                className={pageStyle.box_content_cta_text}
              >
                En savoir plus
              </AniLink>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 0, marginBottom: 0 }} className={pageStyle.box_container_white}>
        <div style={{ justifyContent: 'end' }} className={pageStyle.box_panel_right}>
          <div style={{ textAlign: 'right' }} className={pageStyle.box_content}>
            <h5>Social Ads</h5>
            <p style={{ textAlign: 'right' }}>
              Diffusez vos publicités dans le fil d’actualité des personnes les plus susceptibles
              d’être intéressées par votre marque.{' '}
            </p>
            <p style={{ textAlign: 'right' }}>
              Nous créons plusieurs variantes de vos campagnes publicitaires afin de garder
              uniquement les plus performantes.
            </p>
            <div style={{ textAlign: 'right' }} className={pageStyle.cta}>
              <AniLink
                to="/services/reseaux-sociaux/social-ads/"
                className={pageStyle.box_content_cta_text}
              >
                En savoir plus
              </AniLink>
            </div>
          </div>
        </div>
        <div
          style={boxStyle2}
          className={`${pageStyle.box_panel_left} ${pageStyle.mobile_hidden}`}
        ></div>
      </div>

      <div style={{ marginTop: 0, marginBottom: 0 }} className={pageStyle.box_container_white}>
        <div
          style={boxStyle3}
          className={`${pageStyle.box_panel_left} ${pageStyle.mobile_hidden}`}
        ></div>
        <div style={{ justifyContent: 'start' }} className={pageStyle.box_panel_right}>
          <div className={pageStyle.box_content}>
            <h5>Remarketing</h5>
            <p>Reciblez vos meilleurs leads grâce au remarketing.</p>
            <p>Nous ciblons les personnes qui ont déjà visité votre site internet.</p>
            <div style={{ textAlign: 'left' }} className={pageStyle.cta}>
              <AniLink
                className={pageStyle.box_content_cta_text}
                to="/services/reseaux-sociaux/remarketing/"
              >
                En savoir plus
              </AniLink>
            </div>
          </div>
        </div>
      </div>
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Site web" url="/services/siteweb/" img={similar1} />
          <SimilarCard
            text="Application mobile"
            url="/services/application-mobile/"
            img={similar2}
          />
          <SimilarCard text="Newsletter" url="/services/newsletter/" img={similar3} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
